<template>
  <svg width="124" height="152" viewBox="0 0 124 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50.2176" cy="74.2531" r="38.6681" transform="rotate(21.6807 50.2176 74.2531)" stroke="#C5E7FF" stroke-width="4"/>
    <rect x="76.917" y="99.3418" width="39.6596" height="3.92674" rx="1.96337" transform="rotate(47.7213 76.917 99.3418)" fill="#C5E7FF"/>
    <rect x="95.2139" y="115.869" width="34.3165" height="8.4" rx="2.64397" transform="rotate(47.7213 95.2139 115.869)" fill="#C5E7FF"/>
    <path d="M83 14L85.0206 20.2188H91.5595L86.2694 24.0623L88.2901 30.2812L83 26.4377L77.7099 30.2812L79.7306 24.0623L74.4405 20.2188H80.9794L83 14Z" fill="#C5E7FF"/>
    <path d="M101 6L102.347 10.1459H106.706L103.18 12.7082L104.527 16.8541L101 14.2918L97.4733 16.8541L98.8204 12.7082L95.2937 10.1459H99.6529L101 6Z" fill="#C5E7FF"/>
    <path d="M113 0L113.674 2.07295H115.853L114.09 3.3541L114.763 5.42705L113 4.1459L111.237 5.42705L111.91 3.3541L110.147 2.07295H112.326L113 0Z" fill="#C5E7FF"/>
  </svg>

</template>

<script>
export default {
  name: "JobSearchIcon"
}
</script>

<style scoped>

</style>
<template>
  <div v-if="explore_by_search.length" class="md:tw-grid md:tw-grid-cols-4" :class="[$vuetify.breakpoint.mobile?'':'']">
    <div class="explore-card pt-5 " v-for="(job_in_location, i) in explore_by_search" :key="i">
      <div @click="viewJobDetails(job_in_location)">
        <div class="explore-action mb-4">
          <div class="company-logo2">
            <img v-if="job_in_location.company_logo.startsWith('https')" :src="job_in_location.company_logo"
                 style="width: 32px; height: 32px;"/>
            <default-avatar v-else :company-name="job_in_location.company_name"/>
          </div>
          <div class="save-icon">
            <v-btn v-if="getUserDetails" :loading="job_in_location.loading" text class="d-flex align-center"
                   @click.stop="handleAction(job_in_location.id,'save')">
              <div class="tw-flex tw-items-center" style="text-transform: capitalize;color: #9CABB5" v-if="!job_in_location.is_saved">
                <vue-feather type="bookmark" size="19"/>
                save
              </div>
              <div v-else class="tw-flex tw-items-center save-icon" style="text-transform: capitalize;width: 68px;height: 32px; color: #008EEF; background-color: #F6FCFF ">
                <v-icon small  >mdi-bookmark</v-icon>
                saved
              </div>
            </v-btn>
          </div>
        </div>
        <div class="job-details">
          <span class="job-title">{{ job_in_location.title }}</span>
          <span class="details tw-capitalize">{{ job_in_location.company_name }} <span class="bullet">&bull;</span> <span
              class="job-type">{{ job_in_location.type }}</span> </span>
        </div>
        <div class="explore-action pt-10">
          <span v-if="job_in_location.is_remote === true" class="type"><vue-feather type="map-pin" size="18"
                                                                                    class="mr-1"/>Remote</span>
          <span v-else class="type"><vue-feather type="map-pin" size="18" class="mr-1"/>On-site</span>
          <span class="type time tw-mr-3">{{ processDay(job_in_location.created_at) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultAvatar from "@/components/reusables/DefaultAvatar";
import {savedJob} from "@/services/api/APIService";
import timeDay from "@/mixins/timeDay.mixin";

export default {
  name: "ExploreSearch",
  components: {DefaultAvatar},
  mixins: [timeDay],
  props: {
    explore_by_search: Array,
  },
  data() {
    return {
      loading: false,
      saveJobLoading : false,
    }
  },
  computed: {
    addLoadingToJob() {
      return this.explore_by_search.map((job) => ({
        ...job,
        loading: false
      }))
    },
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData'))||null
    }
  },
  methods: {
    viewJobDetails(data){
      this.goto(data.id)
    },
    handleAction(job_id, type) {
      if (type === "save") {
        this.savedJob(job_id)
      } else {
        this.goto(job_id)
      }
    },
    goto(jobId) {
      console.log('push')
      // change this to another route
      this.$router.push({name:'JobDetailsAsQuestView',
        query: {jobId}
      });
    },
    savedJob(job_id) {
      this.$emit('loading', job_id)
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      let data = {}
      data.job_id = job_id
      data.candidate_email = userData.email
      savedJob(data).then(() => {
        this.$emit('saving', job_id)
        this.$emit('loading', job_id)
      })
          .catch(err => {
            console.log(err)
            let errorKey = Object.keys(err.response.data)
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          }).finally(() => {
      })
    },
  }
}
</script>

<style scoped lang="scss">

.explore-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
  padding: 1rem 0;
  border-bottom: 1px solid #F6F6F6;

}

.explore-card {
  margin: 0.6rem;
  min-width: 18rem;
  max-width: 22rem;
  min-height: 11.25rem;
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  @media (max-width: 765px) {
    width: 100%;
    max-width: 352px;
    min-width: auto;
  }
}

.explore-action {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.save-icon {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9CABB5;

}

.company-logo2 {
  width: 40px;
  height: 40px;

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
}

.job-details {
  display: flex;
  flex-direction: column;

.details {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
}

.type {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #475661;
  display: flex;


}

.job-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #1E323F;

}
.scroll {
  max-height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}
</style>
